import './App.css';
import Home from './pages/Home';
import AppFooter from './components/AppFooter';

function App() {
  return (
    <div className="App">
      <Home />
      <AppFooter />
    </div>
  );
}

export default App;
