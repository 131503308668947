import './AppFooter.css';

function AppFooter() {
    return (
        <footer className="App-footer Font-small">
            <div><span className="Copyright">&copy; 2004-2024 <span className="Logo">SujiPets</span> a trademark of <a href="//draft.ink"><span className="Logo">draft <span className="Color-primary">dot</span> ink</span></a>. All rights reserved.</span></div>
        </footer>
    );
}

export default AppFooter;