import './Home.css';

function Home() {
    return (
        <div className="Home">
            <section className="App-section">
                <div className="App-section-left">
                    <h1 className="Logo">SujiPets</h1>
                    <h2 className="Font-small Text-uppercase Color-medium">We offer</h2>
                    <p>A <span class="Font-bold">100% FREE</span> to play virtual pet site where you can: adopt your own virtual pet, explore, play games, shop, train, feed your pet, battle, and more.</p>
                    <button disabled>Coming Soon</button>
                </div>
            </section>
        </div>
    );
}

export default Home;